<template>
  <div>
    <div v-if="getting" class="flex justify-center h-[50vh]">
      <spinner class="w-20 h-20" color="oneGreen" />
    </div>

    <div
      v-else-if="notFound || !printout"
      class="w-full flex flex-col justify-center items-center h-[50vh]"
    >
      <img
        src="@/assets/images/icons/broken_link.svg"
        alt="icon"
        class="w-20 h-20 transform rotate-180"
      />

      <p class="text-red-400 font-bold mt-2">
        Printout not Available right now, make sure you filled all fields
      </p>
    </div>

    <div v-else class="flex justify-center">
      <div id="printout" v-html="printout" class="mr-3"></div>
      <div>
        <button
          @click="printInvoice"
          class="bg-[#F9F9F9] flex justify-center border rounded px-5 py-2"
        >
          <img src="@/assets/images/icons/uil_print.svg" alt="" />Print
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VerificationPrintout",
  data() {
    return {
      printout: null,
      getting: false,
      notFound: false,
    };
  },
  created() {
    this.fetchPrintout();
  },
  methods: {
    async fetchPrintout() {
      this.getting = true;
      const url = this.$store.state.staff.profile.staffType.includes("pension")
        ? "/pensionier/summary"
        : "/employee/report-summary";

      const res = await this.$http.post(url, {
        baseUrl: `${window.location.origin}/staff-printout`,
      });

      if (!res) {
        return;
      }
      const { data } = res;
      this.getting = false;

      if (data && data.code) {
        this.notFound = true;
        this.$swal({
          icon: "info",
          text: data.message,
        });
        return;
      }
      this.notFound = false;
      this.printout = data;
    },

    printInvoice() {
      const divContents = document.getElementById("printout").innerHTML;
      const printoutDoc = window.open("", "", "");
      printoutDoc.document.write(
        `<html><head><title> ${document.title} </title></head>`
      );
      printoutDoc.document.write("<body>");
      printoutDoc.document.write(divContents);
      printoutDoc.document.write("</body></html>");
      printoutDoc.document.close();
      printoutDoc.focus();
      setTimeout(() => {
        printoutDoc.print();
        printoutDoc.close();
        return true;
      }, 700);
    },
  },
};
</script>

<style scoped>
@media print {
  body * {
    visibility: hidden;
  }

  #printout,
  #printout * {
    visibility: visible;
  }
}
</style>
