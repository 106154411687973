<template>
  <div>
    <template v-if="nibbsProceed">
      <div class="h-[30vh] flex flex-col items-center justify-center">
        <p
          class="py-3 border text-center border-oneGreen bg-oneGreen/20 text-oneGreen mb-2 font-bold px-5 rounded w-full"
        >
          Confirm that you have completed the BVN Verification
        </p>

        <button
          @click="verifyBvn"
          class="bg-oneGreen px-20 mt-3 text-white font-semibold py-2.5 rounded-lg flex justify-center items-center gap-2"
        >
          <span>Click to Confirm</span>
          <spinner v-if="verifying" />
        </button>
      </div>
    </template>

    <template v-else>
      <section>
        <h3 class="font-bold text-xl lg:text-3pxl text-idBlued">
          BVN VERIFICATION
        </h3>
        <h5 class="text-xs lg:text-base">
          Provide your Bank Verification Number (BVN) to proceed.
        </h5>
      </section>

      <form @submit.prevent="getBvnStatus">
        <div>
          <label for="bvn"> BVN<span class="req">*</span> </label>
          <input
            id="bvn"
            type="number"
            placeholder="Enter your BVN"
            v-model="form.bvn"
            class="input"
            pattern="/\d*$/"
            onKeyPress="if(this.value.length==11) return false;"
          />
        </div>

        <div>
          <button :disabled="proceeding || !formReady" type="submit">
            <span>Validate</span>
            <spinner v-if="proceeding"></spinner>
          </button>
        </div>
      </form>

      <section class="px-6 py-4 rounded-lg bg-red-100 mt-6 w-full">
        <p class="text-sm font-medium">
          Please confirm that the BVN provided above is correct. A wrong BVN
          will result in a failed
          <span class="uppercase font-bold">verification process</span>.
        </p>
      </section>
    </template>
  </div>
</template>

<script>
export default {
  name: "BvnForm",

  props: {
    verifying: {
      type: Boolean,
      default: false,
    },
    pending: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      nibbsProceed: this.pending,
      proceeding: false,
      form: {
        bvn: this.$store.state.staff.profile.bvn,
      },
    };
  },

  computed: {
    formReady() {
      return Boolean(this.form.bvn) && /^[0-9]{11}$/.test(this.form.bvn);
    },
  },

  methods: {
    async getBvnStatus() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Please enter a valid BVN",
        });
        return;
      }

      try {
        this.proceeding = true;

        const res = await this.$http.get(`/bvn/status?bvn=${this.form.bvn}`);

        if (!res) {
          this.proceeding = false;
          return;
        }

        const { data } = res;

        if (data.code === "S404") {
          await this.proceed();
        }
      } catch (error) {
        this.proceeding = false;
      }
    },

    async proceed() {
      try {
        const res = await this.$http.post(
          this.BVN_API_URL,
          {
            id: this.$store.state.general.user.verificationCode,
            dataurl: `${this.API_URL}employee/bvn-callback`,
            redirecturl: `${window.location.origin}/bvn-status`,
            channel: "02",
            bvn: this.form.bvn,
          },
          {
            headers: {
              apikey: this.BVN_API_KEY,
            },
          }
        );
        if (!res) {
          this.proceeding = false;
          return;
        }
        const { data } = res;

        setTimeout(() => {
          this.nibbsProceed = true;
          window.open(data, "_blank");
        }, 800);
      } catch (error) {
        this.proceeding = false;
      }
    },

    verifyBvn() {
      this.$emit("verify");
    },
  },
};
</script>

<style scoped>
form {
  @apply bg-litergray rounded-lg p-6 mt-7 shadow;
}

form > div:not(:first-child) {
  @apply mt-6;
}

.req {
  @apply text-red-400;
}

form button[type="submit"] {
  @apply w-full bg-oneGreen text-white font-semibold py-2.5 rounded-lg flex justify-center items-center gap-2;
}

input {
  @apply uppercase;
}
</style>
