<template>
  <div class="">
    <div class="mt-5">
      <div
        class="bg-ansLemon border border-ansGreen py-5 text-center rounded font-bold"
      >
        <p class="text-xs">
          Generate your virtual NIN by dialing this USSD on your phone
        </p>
        <p class="mt-2">*346*3*{{ form.nin }}*348129#</p>

        <p class="text-red-600 text-sm mt-3">
          <span class="text-2xl font-extrabold">&#9888;</span>
          Virtual NIN can only be used
          <span class="font-extrabold">ONCE</span>
        </p>

        <p class="text-blue-600 text-xs">
          Make sure you enter the correct Vritual NIN
        </p>
      </div>
    </div>

    <form @submit.prevent="virtualNin">
      <div class="mt-5">
        <label for="vNin">
          Virtual NIN
          <span class="text-red-400">*</span>
        </label>
        <input
          v-uppercase
          type="text"
          class="input"
          id="vNin"
          :value="form.vNin | formatVnin"
          @input="updateVnin"
          maxlength="19"
          autocomplete="off"
          placeholder="QN85 5004 8259 3418"
        />
      </div>

      <div class="flex justify-between gap-8 mt-14 pb-3">
        <button
          type="button"
          @click="prev"
          class="border-oneGreen border text-oneGreen w-full text-center py-3 rounded font-semibold text-xl"
        >
          Back
        </button>

        <button
          type="submit"
          :disabled="!formReady || verifying"
          class="bg-oneGreen flex justify-center gap-2 text-white w-full text-center py-3 rounded font-semibold text-xl"
        >
          <span>Next</span>
          <spinner v-if="verifying" />
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "NinStep3",

  data() {
    return {
      verifying: false,
      form: {
        vNin: null,
        nin: null,
        phoneNumber: null,
      },
    };
  },
  created() {
    this.form.nin = this.$store.getters["staff/ninPayload"].nin;
    this.form.phoneNumber = this.$store.getters["staff/ninPayload"].phone;
  },
  computed: {
    formReady() {
      return Boolean(this.form.vNin) && this.form.vNin.length === 16;
    },
  },

  filters: {
    formatVnin(value) {
      return value
        ? value
            .match(/.{1,4}/g)
            .join(" ")
            .toUpperCase()
        : "";
    },
  },
  methods: {
    prev() {
      this.$store.commit("staff/saveData", { key: "bvnNinStep", data: 2 });
    },
    updateVnin(e) {
      this.form.vNin = e.target.value.replace(/ /g, "");
    },
    saveData(data) {
      this.$store.commit("staff/updateNINData", data);
    },
    virtualNin() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Enter a correct virtual NIN",
        });
        return;
      }
      try {
        this.verifying = true;

        this.saveData({
          ...this.$store.getters["staff/ninPayload"],
          vNin: this.form.vNin,
        });

        this.$store.commit("staff/saveData", { key: "bvnNinStep", data: 4 });
        this.verifying = false;
      } catch (err) {
        this.verifying = false;
      }
    },
  },
};
</script>
