<template>
  <div class="pb-5">
    <section>
      <h3 class="font-bold text-xl lg:text-3pxl text-idBlued">
        BVN VERIFICATION
      </h3>
    </section>

    <section
      class="rounded-lg bg-litergray shadow p-3.5 flex flex-col lg:flex-row gap-4 lg:gap-8 w-full max-w-[521px] mt-5 lg:mt-7"
    >
      <div>
        <img
          :src="bvn.photograph"
          alt="user image"
          class="w-[150px] h-[150px] lg:w-[173px] lg:h-[173px] rounded border"
        />
      </div>

      <table>
        <tr>
          <td>BVN:</td>
          <td>{{ bvn.bvnCode }}</td>
        </tr>
        <tr>
          <td>Lastname:</td>
          <td>{{ bvn.lastName }}</td>
        </tr>
        <tr>
          <td>Firstname:</td>
          <td>{{ bvn.firstName }}</td>
        </tr>
        <tr>
          <td>Middlename:</td>
          <td>{{ bvn.middleName }}</td>
        </tr>
        <tr>
          <td>Gender:</td>
          <td>{{ bvn.gender }}</td>
        </tr>
        <tr>
          <td>State of Origin:</td>
          <td>{{ bvn.stateOfOrigin }}</td>
        </tr>
        <tr>
          <td>Date of Birth:</td>
          <td>{{ bvn.dateOfBirth | moment("DD-MMM-YYYY") }}</td>
        </tr>
      </table>
    </section>

    <template v-if="showValid">
      <!-- IF PAYROLL NAME MATCHES/FAILS BVN NAME -->
      <template>
        <section v-if="bvn.nameFailed" class="panel panel-fail">
          <img
            src="@/assets/images/icons/user-id-fail.svg"
            alt="icons"
            class="w-8 h-8 lg:w-10 lg:h-10"
          />

          <div>
            <p class="text-[#FF0000] font-medium text-xs lg:text-sm">
              Your Payroll name did not match correctly with your BVN data
            </p>

            <div class="text-xs lg:text-sm font-medium text-[#8D8D8D]">
              <p>
                Payroll Name:
                <span>{{ $store.getters["general/fullName"] }}</span>
              </p>
              <p>
                BVN Name:
                <span>
                  {{ bvn.lastName }} {{ bvn.firstName }}
                  {{ bvn.middleName }}
                </span>
              </p>
            </div>
          </div>
        </section>

        <section v-else class="panel panel-pass">
          <img
            src="@/assets/images/icons/user-id-pass.svg"
            alt="icons"
            class="w-8 h-8 lg:w-10 lg:h-10"
          />

          <p class="text-oneGreen font-medium text-xs lg:text-sm">
            Your Payroll name has matched correctly with your BVN data
          </p>
        </section>
      </template>

      <!-- IF ACCOUNT NUMBER MATCHES BVN -->
      <!-- <template>
      <section v-if="bvn.accountFailed" class="panel panel-fail">
        <img
          src="@/assets/images/icons/shield-fail.svg"
          alt="icons"
          class="w-8 h-8 lg:w-10 lg:h-10"
        />

        <p class="text-[#FF0000] font-medium text-xs lg:text-sm">
          Your Payroll account number ({{ bvn.accountNumber }}) is
          <span class="font-bold">NOT</span> linked to your BVN ({{
            bvn.bvnCode
          }})
        </p>
      </section>

      <section v-else class="panel panel-pass">
        <img
          src="@/assets/images/icons/shield.svg"
          alt="icons"
          class="w-8 h-8 lg:w-10 lg:h-10"
        />

        <p class="text-oneGreen font-medium text-xs lg:text-sm">
          Account number: {{ bvn.accountNumber }} is linked to the BVN:
          {{ bvn.bvnCode }}
        </p>
      </section>
    </template> -->

      <!-- IF DATE OF BIRTH DONT MATCH -->
      <section
        v-if="bvnVerified && bvn.dobFailed"
        class="w-full max-w-[521px] bg-oneWarn/10 flex items-start gap-2.5 mt-4 lg:mt-5 rounded-lg p-4"
      >
        <img
          src="@/assets/images/icons/bvnwarn.svg"
          alt="icons"
          class="w-10 h-10 lg:w-10 lg:h-10"
        />

        <div class="flex flex-col gap-4">
          <p class="text-[#484848] font-medium text-xs lg:text-sm">
            A different Date of Birth has been detected from your BVN records,
            <span class="font-bold">
              do you want to update your "Payroll DOB" with the new date of
              birth?
            </span>
          </p>
          <div class="text-xs lg:text-sm font-medium text-[#8D8D8D]">
            <p>
              Payroll DOB:
              <span>{{
                $store.getters["staff/profile"].dateOfBirth
                  | moment("Do MMMM YYYY")
              }}</span>
            </p>
            <p>
              BVN DOB:
              <span>{{ bvn.dateOfBirth | moment("Do MMMM YYYY") }}</span>
            </p>
          </div>

          <div class="flex flex-col lg:flex-row gap-4 font-semibold">
            <button
              :disabled="proceeding || aproceeding"
              @click="completeBvn(false, 'proceeding')"
              class="border border-litgray rounded-lg text-dkgray py-2.5 px-8 hover:bg-oneWarn hover:text-white flex justify-center items-center gap-2"
            >
              Decline Update
              <spinner v-if="proceeding" />
            </button>

            <button
              :disabled="aproceeding || proceeding"
              @click="completeBvn(true, 'aproceeding')"
              class="bg-oneGreen hover:bg-green-800 rounded-lg text-white py-2.5 px-8 flex justify-center items-center gap-2"
            >
              Accept Update
              <spinner v-if="aproceeding" />
            </button>
          </div>
        </div>
      </section>

      <section class="mt-4 w-full max-w-[521px]">
        <button
          v-if="bvnVerified && !bvn.dobFailed"
          :disabled="proceeding"
          @click="completeBvn(false, 'proceeding')"
          class="w-full py-3 bg-oneGreen text-white flex justify-center items-center gap-2 rounded"
        >
          <span>Complete BVN</span>

          <spinner v-if="proceeding" />
        </button>

        <section v-else-if="bvn.nameFailed" class="panel bg-oneWarn/10">
          <img
            src="@/assets/images/icons/alert-warn.svg"
            alt="icons"
            class="w-8 h-8 lg:w-10 lg:h-10"
          />

          <p class="text-dkgray font-medium text-xs lg:text-sm">
            Your verification process has failed. Send a message to the admin
            through the
            <router-link
              class="text-blue-500 font-bold"
              :to="{ name: 'Contact Us' }"
              target="__blank"
            >
              contact page
            </router-link>
          </p>
        </section>
      </section>
    </template>
  </div>
</template>

<script>
export default {
  name: "BvnVerified",

  props: {
    showValid: {
      type: Boolean,
      default: true,
    },
    bvn: {
      type: Object,
      default: () => ({
        dobFailed: false,
        nameFailed: false,
        accountFailed: false,
      }),
    },
  },

  data() {
    return {
      proceeding: false,
      aproceeding: false,
    };
  },

  computed: {
    bvnVerified() {
      return this.bvn && !this.bvn.nameFailed;
      // && !this.bvn.accountFailed;
    },
  },

  methods: {
    async completeBvn(updateDob, acn) {
      if (!this.bvnVerified) {
        this.$swal({
          icon: "error",
          text: "Please complete BVN verification",
        });
        return;
      }

      try {
        this[acn] = true;

        const res = await this.$http.post("/bvn/complete-bvn", {
          updateDob,
        });

        if (!res) {
          this[acn] = false;
          return;
        }

        const { data } = res;
        this.$emit("verified", data.data);

        this.$swal({
          icon: "success",
          text: "BVN verification completed",
        });
        this.$store.commit("staff/updateVerifyStep", 7);

        this.$router.push({ name: "Pension Verification Summary" });
      } catch (error) {
        this[acn] = false;
      }
    },
  },
};
</script>

<style scoped>
table {
  @apply w-full lg:w-10/12;
}

table tr td {
  @apply text-xs lg:text-base text-dkgray py-1;
}

table tr td:first-child {
  @apply font-bold w-2/5;
}

table tr td:last-child {
  @apply font-medium w-2/5;
}
.panel {
  @apply p-2.5 w-full max-w-[521px] flex items-center gap-2.5 mt-4 lg:mt-5 rounded-lg;
}
.panel-pass {
  @apply bg-oneGreen/10;
}
.panel-fail {
  @apply bg-[#FDEDEF];
}
</style>
