<template>
  <div>
    <template v-if="nibbsProceed">
      <div class="h-[30vh] flex flex-col items-center justify-center">
        <p
          class="py-3 border text-center border-oneGreen bg-oneGreen/20 text-oneGreen mb-2 font-bold px-5 rounded w-full"
        >
          Confirm that you have completed the Account Verification
        </p>

        <button
          @click="verifyBvn"
          class="bg-oneGreen px-20 mt-3 text-white font-semibold py-2.5 rounded-lg flex justify-center items-center gap-2"
        >
          <span>Click to Confirm</span>
          <spinner v-if="verifying" />
        </button>
      </div>
    </template>

    <template v-else>
      <section>
        <h3 class="font-bold text-xl lg:text-3pxl text-idBlued">
          NIN VERIFICATION
        </h3>
      </section>

      <section>
        <Step1 v-if="this.$store.state.staff.bvnNinStep === 1" />

        <Step2 v-if="this.$store.state.staff.bvnNinStep === 2" />

        <Step3 v-if="this.$store.state.staff.bvnNinStep === 3" />

        <Step4
          v-if="this.$store.state.staff.bvnNinStep === 4"
          @nibbsProceed="nibbsProceed = true"
        />
      </section>
    </template>
  </div>
</template>

<script>
import Step1 from "@/components/staff/verification/ninSteps/Step1.vue";
import Step2 from "@/components/staff/verification/ninSteps/Step2.vue";
import Step3 from "@/components/staff/verification/ninSteps/Step3.vue";
import Step4 from "@/components/staff/verification/ninSteps/Step4.vue";
export default {
  name: "NinForm",

  components: {
    Step1,
    Step2,
    Step3,
    Step4,
  },

  props: {
    verifying: {
      type: Boolean,
      default: false,
    },
    pending: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      nibbsProceed: this.pending,
      proceeding: false,
      form: {
        bvn: this.$store.state.staff.profile.bvn,
      },
    };
  },

  computed: {
    formReady() {
      return Boolean(this.form.bvn) && /^[0-9]{11}$/.test(this.form.bvn);
    },
  },

  created() {
    this.$store.commit("staff/saveData", { key: "bvnNinStep", data: 1 });
  },

  methods: {
    async getBvnStatus() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Please enter a valid BVN",
        });
        return;
      }

      try {
        this.proceeding = true;

        const res = await this.$http.get(`/bvn/status?bvn=${this.form.bvn}`);

        if (!res) {
          this.proceeding = false;
          return;
        }

        const { data } = res;

        if (data.code === "S404") {
          await this.proceed();
        }
      } catch (error) {
        this.proceeding = false;
      }
    },

    async proceed() {
      try {
        const res = await this.$http.post(
          this.BVN_API_URL,
          {
            id: this.$store.state.general.user.verificationCode,
            dataurl: `${this.API_URL}employee/bvn-callback`,
            redirecturl: `${window.location.origin}/bvn-status`,
            channel: "02",
            bvn: this.form.bvn,
          },
          {
            headers: {
              apikey: this.BVN_API_KEY,
            },
          }
        );
        if (!res) {
          this.proceeding = false;
          return;
        }
        const { data } = res;

        setTimeout(() => {
          this.nibbsProceed = true;
          window.open(data, "_blank");
        }, 800);
      } catch (error) {
        this.proceeding = false;
      }
    },

    verifyBvn() {
      this.$emit("verify");
    },
  },
};
</script>

<style scoped></style>
