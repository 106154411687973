<template>
  <div class="">
    <div class="mt-5">
      <div
        class="bg-ansLemon border border-ansGreen py-5 text-center rounded font-bold"
      >
        <p class="text-base text-blue-600">
          Please Re-enter the virtual NIN you entered before to continue with
          your verification.
        </p>
        <!-- <p class="mt-2">*346*3*{{ form.nin }}*348129#</p> -->
      </div>
    </div>

    <form @submit.prevent="virtualNin">
      <div class="mt-5">
        <label for="vNin">
          Re-type Virtual NIN
          <span class="text-red-400">*</span>
        </label>
        <input
          v-uppercase
          type="text"
          class="input"
          id="vNin"
          :value="form.vNin | formatVnin"
          @input="updateVnin"
          placeholder="Enter the virtual NIN"
          maxlength="19"
          @paste="disablePaste"
          autocomplete="off"
        />
        <small v-if="repaste" class="text-red-700 text-xs"
          >Please re-type the Virtual NIN</small
        >
        <small v-if="vNinNotReady" class="text-red-700 text-xs font-bold">
          Your Virtual NIN does not match
        </small>
      </div>

      <div class="flex justify-between gap-8 mt-14 pb-3">
        <button
          type="button"
          @click="prev"
          class="border-oneGreen border text-oneGreen w-full text-center py-3 rounded font-semibold text-xl"
        >
          Back
        </button>

        <button
          type="submit"
          :disabled="!formReady || verifying"
          class="bg-oneGreen flex justify-center gap-2 text-white w-full text-center py-3 rounded font-semibold text-xl"
        >
          <span>Next</span>
          <spinner v-if="verifying" />
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "NinStep3",

  data() {
    return {
      showPasteError: false,
      verifying: false,
      form: {
        vNin: null,
        nin: null,
        phoneNumber: null,
      },
    };
  },

  created() {
    this.form.nin = this.$store.getters["staff/ninPayload"].nin;
    this.form.phoneNumber = this.$store.getters["staff/ninPayload"].phone;
  },

  computed: {
    vNinNotReady() {
      return (
        Boolean(this.form.vNin) &&
        this.form.vNin.length === 16 &&
        this.$store.getters["staff/ninPayload"].vNin !== this.form.vNin
      );
    },
    formReady() {
      return (
        Boolean(this.form.vNin) &&
        this.form.vNin.length === 16 &&
        !this.vNinNotReady
      );
    },
    repaste() {
      return this.showPasteError && !this.form.vNin;
    },
  },

  filters: {
    formatVnin(value) {
      return value
        ? value
            .match(/.{1,4}/g)
            .join(" ")
            .toUpperCase()
        : "";
    },
  },

  methods: {
    disablePaste(event) {
      this.showPasteError = true;
      event.preventDefault();
    },
    prev() {
      this.$store.commit("staff/saveData", { key: "bvnNinStep", data: 3 });
    },
    updateVnin(e) {
      this.form.vNin = e.target.value.replace(/ /g, "");
    },
    saveData(data) {
      this.$store.commit("staff/updateNINData", data);
    },
    async virtualNin() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Enter a correct virtual NIN",
        });
        return;
      }

      try {
        this.verifying = true;
        const res = await this.$http.post("/employee/nimc/validate", {
          ...this.form,
          vNin: this.form.vNin.toUpperCase(),
        });

        this.verifying = false;
        if (!res) {
          return;
        }
        const { data } = res;

        if (data.code === "E422") {
          this.$swal({
            icon: "info",
            title: "Virtual NIN already used",
            html: `
              <p>
               <span class="text-2xl font-extrabold text-[red]">&#9888;</span> 
               Kindly ensure you are entering the correct virtual NIN or generate a new one to continue.
              </p>
              `,
          });

          this.$store.commit("staff/saveData", { key: "bvnNinStep", data: 3 });
          return;
        }

        if (data.code === "E400") {
          this.$swal({
            icon: "error",
            text: data.message,
          });

          this.$store.commit("staff/saveData", { key: "bvnNinStep", data: 3 });
          return;
        }

        this.$emit("nibbsProceed");
      } catch (err) {
        this.verifying = false;
      }
    },
  },
};
</script>
