<template>
  <div>
    <section>
      <h3 class="font-bold text-xl lg:text-3pxl text-idBlued">
        BVN VERIFICATION
      </h3>
      <h5 class="text-xs lg:text-base">
        Choose to proceed with either BVN or NIN option for your account
        verification
      </h5>
    </section>

    <section class="mt-7">
      <div class="flex gap-2 items-center">
        <input
          type="radio"
          class=""
          name="option"
          id="nin"
          value="nin"
          v-model="option"
        />
        <label class="font-bold" for="nin"
          >NIN
          <small class="text-purple-500 text-sm">
            (You will be required to generate a Virtual NIN)
          </small>
        </label>
      </div>

      <div class="flex gap-2 items-center mt-3">
        <input
          type="radio"
          class=""
          name="option"
          id="bvn"
          value="bvn"
          v-model="option"
        />
        <label class="font-bold" for="bvn">BVN</label>
      </div>

      <div class="mt-4">
        <button
          @click="proceed"
          class="w-full bg-oneGreen py-3 rounded-lg text-white"
        >
          Proceed
        </button>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ChooseForm",

  props: ["value"],

  data() {
    return {
      option: null,
    };
  },

  methods: {
    proceed() {
      this.$emit("input", this.option);
    },
  },
};
</script>

<style scoped></style>
