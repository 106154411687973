<template>
  <div
    class="w-full px-4 py-3 rounded-lg bg-litergray border-2 border-dashed border-gray"
  >
    <label class="text-xs lg:text-base font-medium"
      ><slot name="formlabel"></slot
    ></label>

    <div
      class="h-12 lg:h-14 bg-white rounded border border-dashed border-[#CED4DA] relative"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <!-- multiple -->
      <input
        type="file"
        :name="`fields[${id}][]`"
        :id="id"
        class="w-px h-px opacity-0 inset-0 overflow-hidden absolute"
        @change="onChange"
        ref="file"
        :accept="acceptedTypes"
      />

      <label
        :for="id"
        class="flex gap-2 justify-center items-center cursor-pointer inset-0 absolute py-4"
      >
        <img src="@/assets/images/icons/cloud_upload.svg" alt="icons" />
        <div>
          Drag and drop or <span class="underline text-blue-400">browse</span>
        </div>
      </label>
    </div>

    <ul class="mt-2 p-2.5 bg-white" v-if="filelist.length" v-cloak>
      <li
        class="text-sm p-1 flex justify-between"
        v-for="(file, i) in filelist"
        :key="i"
      >
        <div class="w-9/12 flex gap-2.5">
          <img
            :src="require('@/assets/images/icons/' + iconType)"
            alt="icons"
            class="w-8 h-8"
          />
          <div class="w-10/12 text-xs lg:text-base">
            <p class="text-[#484848] truncate font-bold">
              {{ file.name }}
            </p>
            <p class="text-[#949494]">
              {{ formatFileSize(file.size) }}
            </p>
          </div>
        </div>

        <button
          class="ml-2 text-xs py-1.5 px-3 underline text-red-500"
          type="button"
          @click="remove(filelist.indexOf(file))"
          title="Remove file"
        >
          remove
        </button>
      </li>
    </ul>

    <div class="flex justify-between items-center py-4">
      <div>
        <p>Scan the copy of your original document (pdf)</p>
        <p>Maximum file size: 2MB</p>
      </div>

      <div>
        <button
          @click="upload"
          :disabled="uploading || !filelist.length"
          class="rounded-lg bg-oneGreen py-1 text-white px-4 flex justify-center items-center gap-2"
        >
          <spinner v-if="uploading"></spinner>
          <span>upload</span>
        </button>
      </div>
    </div>

    <div>
      <small class="req" v-if="sizeFailed">
        File size is either bigger than 2MB or too small
      </small>
      <small class="req" v-if="typeFailed">
        File type is not a valid format
      </small>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomUpload",

  data() {
    return {
      filelist: [],
      fileSizeLimit: 2 * 1024 ** 2, // 2MB
      typeFailed: false,
      sizeFailed: false,
      proceeding: false,
    };
  },

  props: {
    value: {
      default: () => null,
      required: true,
    },
    file: {
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    acceptedTypes: {
      type: String,
      default: "image/png, image/jpeg, image/jpg, application/pdf",
    },
    uploading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    iconType() {
      let icon = "jpeg.svg";
      if (this.filelist.length && this.filelist[0].type.includes("pdf")) {
        icon = "pdf.svg";
      }
      return icon;
    },
    formReady() {
      return Boolean(this.file);
    },
  },

  methods: {
    upload() {
      this.$emit("upload");
    },
    onChange() {
      if (
        (this.$refs.file.files[0] &&
          this.$refs.file.files[0].size > this.fileSizeLimit) ||
        (this.$refs.file.files[0] && this.$refs.file.files[0].size <= 1000)
      ) {
        this.sizeFailed = true;
      } else {
        this.sizeFailed = false;
      }
      if (
        this.$refs.file.files[0] &&
        !this.acceptedTypes.includes(this.$refs.file.files[0].type)
      ) {
        this.typeFailed = true;
      } else {
        this.typeFailed = false;
      }

      if (!this.typeFailed && !this.sizeFailed) {
        this.filelist = [this.$refs.file.files[0]];
        this.$emit("input", this.filelist[0]);
      }
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    formatFileSize(fileSizeInBytes) {
      const kilobyte = 1024;
      const megabyte = kilobyte * 1024;
      const gigabyte = megabyte * 1024;

      if (fileSizeInBytes >= gigabyte) {
        return `${(fileSizeInBytes / gigabyte).toFixed(2)} GB`;
      } else if (fileSizeInBytes >= megabyte) {
        return `${(fileSizeInBytes / megabyte).toFixed(2)} MB`;
      } else if (fileSizeInBytes >= kilobyte) {
        return `${(fileSizeInBytes / kilobyte).toFixed(2)} KB`;
      } else {
        return `${fileSizeInBytes} bytes`;
      }
    },
  },
};
</script>

<style scoped>
[v-cloak] {
  display: none;
}

p {
  @apply text-xs text-mdgray font-medium;
}
</style>
