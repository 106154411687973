<template>
  <div>
    <section v-if="!doc.uploaded" class="mt-3 lg:mt-8">
      <custom-upload
        v-model="doc.file"
        :acceptedTypes="doc.mimeType"
        :id="doc._id"
        :file="doc.file"
        :name="doc.name"
        :uploading="doc.uploading"
        @upload="$emit('upload', doc)"
      >
        <template #formlabel>
          <span>{{ doc.name }}<span class="text-red-400">*</span></span>
        </template>
      </custom-upload>

      <div class="flex justify-end" v-if="doc.approvalStatus">
        <button
          @click="doc.uploaded = true"
          class="text-red-400 underline text-xs lg:text-base"
        >
          cancel
        </button>
      </div>
    </section>

    <section v-else-if="doc.uploaded" class="mt-3 lg:mt-8">
      <div class="bg-litergray rounded-lg p-6">
        <div class="flex justify-between">
          <label :for="doc.name">
            {{ doc.name }}
          </label>
          <p>
            <span v-if="doc.approvalStatus === 'pending'" class="pending">
              &#x23FA; {{ doc.approvalStatus }} approval
            </span>
            <span v-if="doc.approvalStatus === 'approved'" class="approved">
              {{ doc.approvalStatus }}
            </span>
          </p>
        </div>

        <div
          class="flex justify-between items-center bg-white rounded-lg p-2.5 mt-2"
        >
          <div class="gap-2.5 font-bold flex items-center">
            <img
              src="@/assets/images/icons/file.svg"
              alt="icons"
              class="h-8 w-8"
            />

            <div>
              <p class="text-xs lg:text-sm truncate">
                <a
                  :href="doc.documentUrl"
                  :download="doc.documentName"
                  class="underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ doc.documentName }}
                </a>
              </p>
            </div>
          </div>

          <div>
            <button
              v-if="doc.approvalStatus !== 'approved'"
              @click="doc.uploaded = false"
              class="text-oneGreen underline text-xs lg:text-base"
            >
              edit
            </button>
          </div>
        </div>

        <template v-if="doc.approvalStatus === 'pending'">
          <p class="text-red-500 text-xs font-bold" v-if="isFileEmpty">
            File is invalid, Please re-upload file
          </p>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
import CustomUpload from "@/components/form/CustomUpload.vue";
export default {
  name: "DocumentForm",

  components: {
    CustomUpload,
  },

  props: {
    value: {
      required: true,
      default: null,
    },
    doc: {
      type: Object,
      default: () => ({
        uploaded: false,
      }),
    },
  },

  data() {
    return {
      contentLength: 0,
    };
  },

  computed: {
    isFileEmpty() {
      return this.contentLength <= 100;
    },
  },

  async mounted() {
    await this.getFileSize();
  },

  methods: {
    async getFileSize() {
      const response = await fetch(this.doc.documentUrl, { method: "HEAD" });
      const contentLength = response.headers.get("content-length");

      if (contentLength) this.contentLength = Number(contentLength);
    },
  },
};
</script>

<style scoped>
.pending {
  @apply text-purple-600 font-bold text-sm;
}
.approved {
  @apply text-green-600 font-bold text-sm;
}
</style>
